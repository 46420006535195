<template>
  <v-text-field
    type="number"
    :autofocus="autofocus"
    :single-line="singleLine"
    :dense="dense"
    :disabled="disabled"
    :label="label"
    v-model="value"
    ref="value"
    @input="propagateInput"
    :rules="computedRules"
    :readonly="readonly"
    :error-messages="errorMessages"
    v-bind="$attrs"
    v-on="$listeners"
  >
  </v-text-field>
</template>

<script>
export default {
  data: () => ({
    internalValue: undefined
  }),
  methods: {
    propagateInput (e) {
      this.$emit('input', e)
    }
  },
  async mounted () {
    const input = await this.$waitFor(() => this.$refs.value?.$refs.input)
    if (input) {
      input.step = 'any'
    }
  },
  computed: {
    computedRules () {
      return [
        () => (this.$refs.value?.$refs.input.validity.valid ?? true) || this.$t('t.MustBeNumber'),
        ...this.rules
      ]
    },
    value: {
      get () {
        return this.internalValue
      },
      set (val) {
        this.internalValue = val
        if (val?.length) {
          this.$emit('update:number', Number(val))
        } else if (this.nullable || this.$refs.value?.badInput) {
          this.$emit('update:number', null)
        }
        this.$refs.value?.validate()
      }
    }
  },
  watch: {
    number: {
      handler (v) {
        if ((this.$refs.value?.$refs.input.validity.valid ?? true) && Number(v) !== Number(this.internalValue)) {
          this.internalValue = v
        }
      },
      immediate: true
    }
  },
  props: {
    autofocus: Boolean,
    dense: Boolean,
    disabled: Boolean,
    errorMessages: String,
    label: String,
    nullable: {
      default: false,
      type: Boolean
    },
    number: Number,
    readonly: Boolean,
    rules: {
      default: () => [],
      type: Array
    },
    singleLine: Boolean
  }
}
</script>
